.cta {

	&.has-bg-color-cut {
		position: relative;
		background-color: transparent;
		z-index: auto;
		
		&::before {
			content: '';
			width: 100%;
			height: 50%;
			position: absolute;
			left: 0;
			bottom: 0;
			background: get-color(dark, 1);
			z-index: -3;
		}
    }
    
    h3 {
        @include font-family(heading);
        @include font-weight(heading);
        color: get-color(light, 1);
    }

    .form-input {
        border-color: transparent;
        border-radius: 2px;
    }
}

.cta-inner {
	padding-left: 16px;
	padding-right: 16px;
	background-color: get-color(primary, 3);
}

@include media( '>medium' ) {

.cta {

    .form-input {
        min-width: 280px;
    }
}    

	.cta-inner {
		padding-left: 48px;
		padding-right: 48px;
	}
}
